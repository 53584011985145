.SideDrawer{
    position: fixed;
    width:280px;
    height:100%;
    left:0;
    top:0;
    z-index: 200;
    background-color:#3278bd;;
    padding:32px 16px;
    box-sizing: border-box;
    transition: transform 0.3s ease-out;
}
@media (min-width:500px){
    .SideDrawer{
        display:none;
    }
}
.Open{
    transform:translateX(0);
}
.Close{
    transform: translateX(-100%);
}