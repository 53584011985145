.Card{
margin: 10%;
}
.p{
    color:rgb(175, 169, 169);
}
.h1{
    font-weight: 700;
}
    .Row{
        margin-bottom :15px;
        padding: 10px;
        border-bottom: 1px solid #3278bd;
    }
    .Icon:hover{
        color: #3278bd;
    }

    .h2{
        margin-left: 7%;
        font-weight: 400;
        font-size: larger;
        margin-top: 5%;
    }
    @media screen and (max-width: 600px) {
        .Card{
            width:100%;
            left:1
            }
            .p{
                color:rgb(175, 169, 169);
            }
            .h1{
                font-weight: 300;
            }
                .Row{
                    margin-bottom :3px;
                    padding: 2px;
                    border-bottom: 1px solid #3278bd;
                }
                .Icon:hover{
                    color: #3278bd;
                }

                .h2{

                    font-weight: 200;
                    font-size: small;
                    margin-top: 13%;
                }
                .info{
                    font-size: small;
                }
    }