.question{
    color:white;
    background-color: #3278bd;
    margin:10px;
    padding:10px;
    border-radius: 7px;
}
.question:hover{
    color:#3278bd;
    background-color: white;
    margin:10px;
    padding:10px;
    border-radius: 7px;
    cursor: pointer;
}
.range{
    -webkit-transform:rotate(90deg);
width:100%;
margin-top: 80%;
list-style: none;
}
.h2{
    font-size: x-large;
    font-weight: 600;
    margin-top: 10%;
}
.answer{
    color:gray;
    margin-left: 5%;
}
.img{
    width: 20%;
}
.minus{
    margin-bottom: -50px !important;
}

@media screen and (max-width: 600px) {
    .h2{
        font-size:medium ;
        font-weight: 600;
        margin-top: 40%;
    }
    .description {
        font-size:large ;
        font-weight: 600;
        margin-top: 3%;
    }
  
    .minus{
        margin-bottom: -100px !important;
    }
    .img {
       
        width: 50%;
    }

}