.question{
    color:white;
    background-color: #3278bd;
    margin:10px;
    padding:10px;
    border-radius: 7px;
}
.question:hover{
    color:#3278bd;
    background-color: white;
    margin:10px;
    padding:10px;
    border-radius: 7px;
    cursor: pointer;
}
.range{
    -webkit-transform:rotate(90deg);
width:100%;
margin-top: 80%;
list-style: none;
}
.h2{
    font-size: x-large;
    font-weight: 600;
}
.answer{
    color:gray;
    margin-left: 5%;
}
.card{
    width:90%;
}