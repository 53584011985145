.NavigationItem{
    margin: 10px 5px;
    box-sizing: border-box;
    display: block;
    width:100%;

}
.NavigationItem a{
    color:white;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
}
.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active{

    color:#40A4c8;
}
@media (min-width:500px){
    .NavigationItem{
        margin:0;
        box-sizing: border-box;
        display: flex;
        width:auto;
        height: 100%;
        align-items: center;
    }
    .NavigationItem a{
        color:white;
        text-decoration: none;
        width: 100%;
        box-sizing: border-box;
        display: block;
        margin-left:20px ;
    }
    .NavigationItem a:hover,
    .NavigationItem a:active,
    .NavigationItem a.active{

        border-bottom:2px solid black;
        color:white;
    }

}
