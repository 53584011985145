.Content {
    margin-top: 72px;
    background-color: #f4f7fc;
    margin-right: 5%;
    margin-left: 5%;
}
@media screen and (max-width: 600px) {
    .Content {
        margin-top: 15px;
        background-color: #f4f7fc;
        margin-right: 1%;
        left:1px
    }
}