.Card{
    padding: 2%;
}
.Card1{
    padding:5%;
    min-height: 200px;
}
.Circle{
    color:green;
    border-radius: 50%;
}
    .Row{
        display: flex;
        flex:wrap;
        justify-content: space-between;
        margin :15px;
        border-radius: 5px;
        padding: 10px;
    }
    .Row:hover{
        display: flex;
        flex:wrap;
        justify-content: space-between;
        margin :15px;
        border-radius: 5px;
        background-color: rgb(69, 202, 120);
        color:white;
        padding: 10px;
    }
    .Page{
        display: flex;
        justify-content: space-evenly;
        text-align: center;
    }
    .h2{
       font-size:60px;
       margin-top: -4px;
    }
    .p{
        color:silver;
    }
    .pg{
        color:green;
        font-weight: 500;
    }