.Add {
  background-color: white;
  padding: 8%;
  border: 1px solid;
  text-align: center;
  height: 50%;
}
.Add:hover {
  background-color: white;

  border: 1px solid;
  text-align: center;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .Add {
    background-color: white;
    padding: 15%;
    border: 1px solid;
    text-align: center;
    margin-top: 2%;
  }
  .Add:hover {
    background-color: white;

    border: 1px solid;
    text-align: center;
    cursor: pointer;
  }
}
