.Add{
    text-align: center;
    color: red;
    box-shadow: 5px 5px #888888;
    cursor: pointer;
    margin-top: 15px;
}
.h2{
    color:rgb(78, 75, 75);
}
.Questions{
    width:70%;
}
.p{margin:1%}
.Card{
margin-top: 20%;
}
.Row{
    display: flex;
    flex:wrap;
    justify-content: space-between;
    margin :15px;
    border-radius: 5px;
    padding: 10px;
}
.Row:hover{
    display: flex;
    flex:wrap;
    justify-content: space-between;
    margin :15px;
    border-radius: 5px;
    background-color: rgb(69, 202, 120);
    color:white;
    padding: 10px;
}
.trash:hover{
    cursor: pointer;
}
.trash{
    margin-top:7%;
    color:red;
    margin-left:"1%";
}
.select{
position: relative;}

@media screen and (max-width: 600px) {
    .Add{
        text-align: center;
        color: red;
        box-shadow: 5px 5px #888888;
        cursor: pointer;
        margin-top: 15px;
    }
    .h2{
        color:rgb(78, 75, 75);
    }
    .Questions{
        width:70%;
    }
    .p{margin:1%}
    .Card{
    margin-top: 20%;
    }
    .Row{
        display: flex;
        flex:wrap;
        justify-content: space-between;
        margin :15px;
        border-radius: 5px;
        padding: 10px;
    }
    .Row:hover{
        display: flex;
        flex:wrap;
        justify-content: space-between;
        margin :15px;
        border-radius: 5px;
        background-color: rgb(69, 202, 120);
        color:white;
        padding: 10px;
    }
    .trash:hover{
        cursor: pointer;
    }
 .trash{
     margin: auto;
     color:red;
 }
    .select{
    position: relative;}
}