.Card{
width:50%;
max-height: 5%;
padding: 1%;
}
.Row{
    display: flex;
    flex:wrap;
    justify-content: space-between;
    margin :15px;
    box-shadow: 5px 5px #888888;
    border-radius: 5px;
    padding: 10px;

}
.Icon:hover{
    color: #3278bd;
}
.Page{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 3%;

}
.h2{
    margin-left: 7%;
    font-weight: 400;
    font-size: larger;
    margin-top: 5%;
}
.tooltip{
    border-radius: 5px;
    background-color: black;
    color: white;
}
@media screen and (max-width: 600px) {
    .Card{
        width:90%;
        }
        .Row{
            display: flex;
            justify-content: space-between;
            margin :5px;
            box-shadow: 5px 5px #888888;
            border-radius: 5px;
            padding: 3px;
        }
        .Icon:hover{
            color: #3278bd;
        }
        .Icon{
            size-adjust: 5px;
        }
        .Page{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            margin: 3%;
        }
        .Responses{
            display: flex;
            justify-content: space-evenly;
        }
        .h2{
            margin-left: 7%;
            font-weight: 400;
            font-size: small;
            margin-top: 5%;
        }
        .info{
            font-size: small;
        }
  }